





















































































































































import { DateTime } from 'luxon';
import { mapActions, mapState } from 'vuex';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import VStatus from '@/components/VStatus.vue';
import formatDate from '@/helpers/formatDate';
import { doDownload } from '@/helpers/download';
import { toLower, upperFirst } from 'lodash';
import {
  EOperatorInvoiceStatus,
  OperatorInvoiceHistoryItem,
  OperatorInvoiceTypeNaming,
  VuetifyTableOptions
} from '@/api/schema';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { AxiosError } from 'axios';
import {
  getSuperAdminInvoiceReportPdf,
  getSuperAdminInvoices,
  updateSuperAdminInvoiceStatus,
  deleteSuperAdminInvoice
} from '@/api/SuperAdmin';

const statusMap = {
  PENDING: {
    label: 'Pending',
    value: EOperatorInvoiceStatus.PENDING,
    type: 'warning'
  },
  PAID: {
    label: 'Paid',
    value: EOperatorInvoiceStatus.PAID,
    type: 'success'
  },
  NOT_PAID: {
    label: 'Not paid',
    value: EOperatorInvoiceStatus.NOT_PAID,
    type: 'error'
  },
  CANCELLED: {
    label: 'Cancelled',
    value: EOperatorInvoiceStatus.CANCELLED,
    type: 'disabled'
  }
};

const defaultDateData = {
  from: DateTime.now().startOf('month').toFormat('yyyy-LL-dd'),
  to: DateTime.utc().toFormat('yyyy-LL-dd')
};

export default {
  name: 'SuperAdminInvoicesView',
  components: {
    SuperAdminInvoicesCreateFile: (): any =>
      import(
        '@/views/SuperAdminViews/super-admin-invoices/SuperAdminInvoicesCreateFile.vue'
      ),
    VExtendedDataPicker,
    VStatus
  },
  data(): any {
    return {
      statusMap,
      OperatorInvoiceTypeNaming,
      isLoading: false,
      filterMenu: false,
      searchPhrase: '',
      status: '',
      statusFilterOptions: Object.values(statusMap),
      dates: { ...defaultDateData },
      headers: [
        { text: 'Date', value: 'date', width: '13%' },
        { text: 'Document#', value: 'number', width: '13%' },
        { text: 'Type', value: 'type', width: '13%' },
        { text: 'Brandname', value: 'brandName', width: '13%' },
        { text: 'Amount', value: 'amount', width: '12%' },
        { text: 'Description', value: 'description', width: '12%' },
        { text: 'Status', value: 'status', width: '12%' },
        { text: 'Action', value: 'action', width: '12%' }
      ],
      invoices: [] as OperatorInvoiceHistoryItem[],
      total: 0,
      pageData: {
        page: 1,
        itemsPerPage: 10
      },
      showCreateFileDialog: false
    };
  },
  computed: {
    ...mapState('app', ['isSuperAdmin']),
    canUpdateInvoice(): boolean {
      return this.$role.is(['super-admin', 'super-admin-finance']);
    },
    currentPage(): number {
      return this.pageData.page - 1;
    }
  },
  watch: {
    searchPhrase(newVal: string): void {
      if (!newVal) {
        this.loadInvoices();
      }
    }
  },
  methods: {
    ...mapActions('SuperAdmin', ['getAccounts']),
    formatDate,
    changeDateHandler(value: string[]): void {
      this.dates = value;
      this.loadInvoices();
    },
    updatePageOptionsHandler(options: VuetifyTableOptions): void {
      this.pageData = options;
      this.loadInvoices();
    },
    async loadInvoices(): Promise<void> {
      try {
        this.isLoading = true;
        const res = await getSuperAdminInvoices({
          page: this.searchPhrase ? 0 : this.currentPage,
          size: this.pageData.itemsPerPage,
          ...this.dates,
          ...(this.status && { status: this.status }),
          ...(this.searchPhrase && { brandName: this.searchPhrase })
        });
        this.invoices = res.content;
        this.total = res.totalElements;
      } catch (e) {
        errorToastMessage(e);
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    async getReportPdf(data: OperatorInvoiceHistoryItem): Promise<void> {
      try {
        this.isLoading = true;
        const typeFile = upperFirst(toLower(data.type));
        const brandName = data.brandName?.replaceAll(' ', '_') || '';
        const dateFile = formatDate(data.date, 'yyyy.LL.dd');
        const res = await getSuperAdminInvoiceReportPdf(data.id);

        const url = window.URL.createObjectURL(
          new Blob([res.data], {
            type: 'application/pdf'
          })
        );
        doDownload(url, `${typeFile}_Trueplay_${brandName}_${dateFile}.pdf`);
      } catch (e) {
        errorToastMessage(e);
        console.error(e);
      } finally {
        this.isLoading = false;
      }
    },
    changeStatusHandler(
      data: OperatorInvoiceHistoryItem,
      newStatus: string
    ): void {
      updateSuperAdminInvoiceStatus(data.id, newStatus)
        .then(() => {
          this.$toast.success('Status updated');
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally(() => {
          this.loadInvoices();
        });
    },
    setStatusFilter(action: 'clear' | 'apply' = 'apply'): void {
      if (action === 'clear') {
        this.status = '';
      }

      this.filterMenu = false;
      this.loadInvoices();
    },
    deleteInvoice(item: OperatorInvoiceHistoryItem): void {
      this.isLoading = true;
      deleteSuperAdminInvoice(item.id).then(() => {
        this.$toast.success(`Document No. ${item.number} deleted`);
        this.loadInvoices();
      });
    }
  }
};
