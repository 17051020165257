var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"super-admin-invoices",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-h5 font-weight-medium"},[_vm._v("Invoices")])])],1),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"loading":_vm.isLoading}},[_c('div',{staticClass:"d-flex w-full pa-6"},[_c('div',{staticClass:"super-admin-invoices__search d-flex w-full justify-start"},[_c('v-text-field',{staticClass:"w-full",staticStyle:{"max-width":"700px"},attrs:{"label":"Search brandname","clearable":"","dense":"","outlined":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.loadInvoices.apply(null, arguments)}},model:{value:(_vm.searchPhrase),callback:function ($$v) {_vm.searchPhrase=$$v},expression:"searchPhrase"}}),_c('v-btn',{staticClass:"ml-4",attrs:{"color":"primary","block":_vm.$vuetify.breakpoint.smAndDown,"disabled":!_vm.searchPhrase},on:{"click":_vm.loadInvoices}},[_vm._v("Search")])],1),_c('v-menu',{attrs:{"offset-y":"","left":"","nudge-bottom":"20","min-width":"324","close-on-content-click":false,"content-class":"white"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"rounded ml-4 px-1",attrs:{"min-width":"38","outlined":"","color":_vm.filterMenu || _vm.status ? 'primary' : ''}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v("mdi-filter-outline")])],1)]}}]),model:{value:(_vm.filterMenu),callback:function ($$v) {_vm.filterMenu=$$v},expression:"filterMenu"}},[_c('h6',{staticClass:"mt-4 mb-2 mx-3 text-body-1 font-weight-bold"},[_vm._v("Invoice Status")]),_c('v-list',{staticClass:"py-0"},[_c('v-list-item-group',{model:{value:(_vm.status),callback:function ($$v) {_vm.status=$$v},expression:"status"}},_vm._l((_vm.statusFilterOptions),function(option){return _c('v-list-item',{key:option.value,staticClass:"px-2",attrs:{"value":option.value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"color":"primary","dense":""}})],1),_c('v-list-item-content',{staticClass:"text-body-1"},[_vm._v(_vm._s(option.label))])]}}],null,true)})}),1)],1),_c('div',{staticClass:"d-flex justify-end pa-3"},[_c('v-btn',{attrs:{"small":"","width":"72","color":"secondary","outlined":""},on:{"click":function($event){return _vm.setStatusFilter('clear')}}},[_vm._v("Clear")]),_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","width":"72","elevation":"0","color":"primary"},on:{"click":_vm.setStatusFilter}},[_vm._v("Apply")])],1)],1),_c('v-extended-data-picker',{staticClass:"ml-4",attrs:{"max-today":"","range":""},on:{"input":_vm.changeDateHandler},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}}),(_vm.canUpdateInvoice)?_c('v-btn',{staticClass:"ml-4",attrs:{"color":"success"},on:{"click":function($event){_vm.showCreateFileDialog = true}}},[_c('span',[_vm._v("Create File")]),_c('v-icon',{staticClass:"ml-2 mr-0"},[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('v-card-text',[_c('v-data-table-with-pagination',{attrs:{"headers":_vm.headers,"items-per-page":20,"items":_vm.invoices,"options":_vm.pageData,"server-items-length":_vm.total,"footer-props":{ 'items-per-page-options' : [5, 15, 25, 50] },"disable-sort":""},on:{"update:options":[function($event){_vm.pageData=$event},_vm.updatePageOptionsHandler]},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.OperatorInvoiceTypeNaming[item.type]))]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.formatDate(item.date, 'yyyy-LL-dd', false, false)))]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("numeralSpaces")(item.amount,'0,0.[00]'))+" USD")]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(_vm.canUpdateInvoice)?[_c('v-select',{staticClass:"my-3",attrs:{"hide-details":"","dense":"","outlined":"","items":_vm.statusFilterOptions,"item-text":"label","value":item.status,"menu-props":{ offsetY: true }},on:{"input":function($event){return _vm.changeStatusHandler(item, $event)}}})]:_c('VStatus',{staticClass:"text-body-2",attrs:{"type":_vm.statusMap[item.status].type,"small":""}},[_vm._v(_vm._s(_vm.statusMap[item.status].label))])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"d-flex align-center text-body-2 cursor-pointer",attrs:{"role":"button"},on:{"click":function($event){return _vm.getReportPdf(item)}}},[_c('v-icon',{staticClass:"mr-1 text--secondary",attrs:{"size":"20"}},[_vm._v("mdi-file-download-outline")]),_vm._v("PDF")],1),(_vm.canUpdateInvoice)?_c('v-btn',{staticClass:"ml-4",attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.deleteInvoice(item)}}},[_c('v-icon',[_vm._v("mdi-delete-outline")])],1):_vm._e()],1)]}}])})],1)],1)],1)],1),(_vm.canUpdateInvoice)?_c('SuperAdminInvoicesCreateFile',{on:{"update-list":_vm.loadInvoices},model:{value:(_vm.showCreateFileDialog),callback:function ($$v) {_vm.showCreateFileDialog=$$v},expression:"showCreateFileDialog"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }